import Chart from 'chart.js/auto';

/**
 * @typedef {Object} InTransitShipmentTotal
 * @property {Number} AirTotal
 * @property {Number} OceanTotal
 * @property {Number} GroundTotal
 */

/**
 * @typedef {Object} ChartLanePairVolume
 * @property {String} Label
 * @property {String} Title
 * @property {Number} TotalCount
 * @property {Number} TotalWeight
 */

/**
 * @typedef {Object} ChartLanePairVolumeByPeriod
 * @property {String} ModeOfTransport
 * @property {String} AggregatePeriod
 * @property {Number} TotalCount
 * @property {Number} TotalWeight
 */

/**
 * @typedef {Object} ChartSpendOverTime
 * @property {String} Product
 * @property {String} AggregatePeriod
 * @property {Number} TotalAmount
 */

/**
 * @typedef {Object} MaterialReceiptWarehouseCountByName
 * @property {String} WarehouseName
 * @property {Number} Count
 */

/**
 * @typedef {Object} PurchaseOrderCountByStatus
 * @property {String} StatusName
 * @property {Number} Count
 */

/**
 * @typedef {Object} MaterialReceiptCountByStatus
 * @property {String} StatusName
 * @property {Number} Count
 */

/**
 * @typedef {Object} ShipmentDeliveryPerformance
 * @property {string} ModeOfTransport 
 * @property {string} PortOfLadingCityCode  
 * @property {string} PortOfLadingCityCountryCode 
 * @property {string} PortOfDischargeCityCode  
 * @property {string} PortOfDischargeCityCountryCode  
 * @property {string} PortOfLadingLocation 
 * @property {string} PortOfDischargeLocation  
 * @property {number} ShipmentCount  
 * @property {number} TotalWeight  
 * @property {number} MedianTransitTime  
 * @property {number} OnTimePercentage  
 * @property {Date} PastAggregateDate 
 * @property {Date} CurentAggregateDate
 */

/**
 * @typedef {Object} EmissionMetricsByDate
 * @property {number} EmissionsTotal  
 * @property {number} EmissionsIntensity 
 * @property {number} EmissionsWellToTank  
 * @property {number} EmissionsTankToWheel  
 * @property {Date} AggregateDate 
 * @property {string} FormattedDate  
 */

/**
 * @typedef {Object} EmissionProductIntensityByDate
 * @property {string} Product  
 * @property {number} EmissionsIntensity  
 * @property {Date} AggregateDate 
 * @property {string} FormattedDate 
 */

/**
 * @typedef {Object} PeriodicInvoiceBalance
 * @property {number} AmountUsd  .
 * @property {string} DateRange 
 * @property {Date} StartDate  
 * @property {Date|null} EndDate 
 */




const airHex = '#48c78e';
const oceanHex = '#3e8ed0';
const groundHex = '#dde5ec';
const warehouseHex = '#0A0A0A'
const brokerageHex = '#2D7D8F'
const urgentAirHex = '#FF3860'

const countHex = '#01803E';
const volumeHex = '#132D76';
const transitMedianHex = '#7CBF9A';
const transitCountHex = '#7DA4BC';

const transparentize = hex => {
    const opacity = 0.8;
    const tempHex = hex.replace('#', '');
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
}

/**
 * @param {String} canvasId
 * @param {String} datasetLabel
 * @param {InTransitShipmentTotal} model
 */
export function inTransit(canvasId, datasetLabel, model) {

    setTimeout(() => {
        let chart = Chart.getChart(canvasId);

        if (chart) {
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'doughnut',
            data: {
                labels: ['Air', 'Ocean', 'Ground'],
                datasets: [{
                    label: datasetLabel,
                    data: [
                        model.AirTotal ?? 0,
                        model.OceanTotal ?? 0,
                        model.GroundTotal ?? 0
                    ],
                    backgroundColor: [
                        transparentize(airHex),
                        transparentize(oceanHex),
                        transparentize(groundHex)
                    ]
                }]
            }
        });
    }, 0);
}

/**
 * @param {String} canvasId
 * @param {ChartLanePairVolume []} models
 */
export function lanePairChart(canvasId, models) {

    setTimeout(() => {
        const countValues = [];
        const volumeValues = [];

        const labels = [...new Set(models.map((r) => r.Label))];

        const titles = [...new Set(models.map((r) => r.Label))].map(label => {
            return models.find(obj => obj.Label === label).Title;
        });

        for (let i = 0; i < labels.length; i++) {
            countValues.push(models.filter(r => r.Label === labels[i]).reduce((a, b) => a + parseInt(b.TotalCount), 0));
            volumeValues.push((Math.round(models.filter(r => r.Label === labels[i]).reduce((a, b) => a + parseFloat(b.TotalWeight), 0) * 1000) / 1000));
        }

        let chart = Chart.getChart(canvasId);

        if (chart) {
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Count',
                        data: countValues,
                        borderColor: countHex,
                        backgroundColor: transparentize(countHex),
                        yAxisID: 'y0',
                    },
                    {
                        label: 'Volume',
                        data: volumeValues,
                        borderColor: volumeHex,
                        backgroundColor: transparentize(volumeHex),
                        yAxisID: 'y1',
                    }
                ]
            },
            options: {
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            title: function (context) {
                                return titles[context[0].dataIndex];
                            },
                            label: function (context) {
                                if (context.dataset.yAxisID === 'y0') {
                                    return context.dataset.label + ': ' + (context.dataset.data[context.dataIndex].toLocaleString()) + ' shipments';
                                } else {
                                    return context.dataset.label + ': ' + (context.dataset.data[context.dataIndex].toLocaleString()) + ' kg';
                                }
                            }
                        }
                    }
                },
                scales: {
                    y0: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        ticks: { color: countHex },
                        grid: {
                            display: false
                        },
                        title: {
                            display: true,
                            text: 'Count (shipments)',
                            color: countHex
                        }
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        ticks: { color: volumeHex },
                        grid: {
                            display: false
                        },
                        title: {
                            display: true,
                            text: 'Volume (kg)',
                            color: volumeHex
                        }
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }

                }
            }
        });
    }, 0);
}

/**
 * @param {String} canvasId
 * @param {ChartLanePairVolumeByPeriod []} models
 */
export function lanePairAltChart(canvasId, models) {

    setTimeout(() => {
        const countValues = [];
        const volumeValues = [];

        const labels = [...new Set(models.map((r) => r.AggregatePeriod))];

        for (let i = 0; i < labels.length; i++) {
            countValues.push(models.filter(s => s.AggregatePeriod === labels[i]).reduce((a, b) => a + parseInt(b.TotalCount), 0));
            volumeValues.push((Math.round(models.filter(s => s.AggregatePeriod === labels[i]).reduce((a, b) => a + parseFloat(b.TotalWeight), 0) * 1000) / 1000));
        }

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Count',
                        data: countValues,
                        borderColor: countHex,
                        backgroundColor: transparentize(countHex),
                        yAxisID: 'y0',
                    },
                    {
                        label: 'Volume',
                        data: volumeValues,
                        borderColor: volumeHex,
                        backgroundColor: transparentize(volumeHex),
                        yAxisID: 'y1',
                    }
                ]
            },
            options: {
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                if (context.datasetIndex === 0) {
                                    return context.dataset.label + ': ' + (context.dataset.data[context.dataIndex].toLocaleString()) + ' shipments';
                                } else {
                                    return context.dataset.label + ': ' + (context.dataset.data[context.dataIndex].toLocaleString()) + ' kg';
                                }
                            }
                        }
                    }
                },
                scales: {
                    y0: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        ticks: { color: countHex },
                        grid: {
                            display: false
                        },
                        title: {
                            display: true,
                            text: 'Count (shipments)',
                            color: countHex
                        }
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        ticks: { color: volumeHex },
                        grid: {
                            display: false
                        },
                        title: {
                            display: true,
                            text: 'Volume (kg)',
                            color: volumeHex
                        }
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }

                }
            }
        });
    }, 0);
}

/**
 * @param {String} canvasId
 * @param {ChartSpendOverTime []} models
 */
export function spendingOverTimeChart(canvasId, models) {

    setTimeout(() => {
        const airValues = [];
        const groundValues = [];
        const oceanValues = [];
        const brokerageValues = [];
        const warehouseValues = [];
        const urgentAirValues = [];

        const filters = [...new Set(models.map(q => q.Product))];
        const labels = [...new Set(models.map(r => r.AggregatePeriod))];

        for (let i = 0; i < labels.length; i++) {
            if (filters.includes('Air')) {
                airValues.push(parseFloat(models.find(s => s.AggregatePeriod === labels[i] && s.Product === 'Air').TotalAmount))
            }
            if (filters.includes('Ocean')) {
                oceanValues.push(parseFloat(models.find(s => s.AggregatePeriod === labels[i] && s.Product === 'Ocean').TotalAmount))
            }
            if (filters.includes('Ground')) {
                groundValues.push(parseFloat(models.find(s => s.AggregatePeriod === labels[i] && s.Product === 'Ground').TotalAmount))
            }
            if (filters.includes('Urgent Air')) {
                urgentAirValues.push(parseFloat(models.find(s => s.AggregatePeriod === labels[i] && s.Product === 'Urgent Air').TotalAmount))
            }
            if (filters.includes('Warehouse')) {
                warehouseValues.push(parseFloat(models.find(s => s.AggregatePeriod === labels[i] && s.Product === 'Warehouse').TotalAmount))
            }
            if (filters.includes('Custom Brokerage')) {
                brokerageValues.push(parseFloat(models.find(s => s.AggregatePeriod === labels[i] && s.Product === 'Custom Brokerage').TotalAmount))
            }
        }

        let chart = Chart.getChart(canvasId);

        let formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: "bar",
            data: {
                labels: labels,
                datasets: [{
                    borderColor: airHex,
                    backgroundColor: transparentize(airHex),
                    data: airValues,
                    label: 'Air',
                },
                {
                    borderColor: oceanHex,
                    backgroundColor: transparentize(oceanHex),
                    data: oceanValues,
                    label: 'Ocean',
                },
                {
                    borderColor: groundHex,
                    backgroundColor: transparentize(groundHex),
                    data: groundValues,
                    label: 'Ground',
                },
                {
                    borderColor: urgentAirHex,
                    backgroundColor: transparentize(urgentAirHex),
                    data: urgentAirValues,
                    label: 'Urgent Air',
                },
                {
                    borderColor: warehouseHex,
                    backgroundColor: transparentize(warehouseHex),
                    data: warehouseValues,
                    label: 'Warehouse',
                },
                {
                    borderColor: brokerageHex,
                    backgroundColor: transparentize(brokerageHex),
                    data: brokerageValues,
                    label: 'Custom Brokerage',
                }]
            },
            options: {
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                        grid: {
                            display: false
                        },
                    },
                    y: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'USD'
                        },
                        grid: {
                            display: false
                        },
                        ticks: {
                            callback: function (value, index, ticks) {
                                // Include a dollar sign in the Y axis ticks
                                return '$' + value.toLocaleString();
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            label: function (tooltipItem) {
                                // Include a $ in all labels during the tooltip popup
                                let dataSet = tooltipItem.dataset
                                return dataSet.label + ': $' + formatter.format(dataSet.data[tooltipItem.dataIndex]);
                            },
                            footer: (tooltipItems) => {
                                // Display an aggregate total at the bottom of the tooltip popup
                                let total = tooltipItems.reduce((a, e) => {
                                    return a + parseFloat(e.parsed.y)
                                }, 0);

                                return 'Total: $' + formatter.format(total);
                            }
                        }
                    }
                }
            }
        });
    }, 0);
}

/**
* @param {String} canvasId
* @param {MaterialReceiptWarehouseCountByName []} models
*/
export function materialReceiptWarehouseCountByNameChart(canvasId, models) {

    setTimeout(() => {

        const labels = models.map(materialReceiptWarehouseCountByName => materialReceiptWarehouseCountByName.WarehouseName)

        const chartData = models.map(materialReceiptWarehouseCountByName => materialReceiptWarehouseCountByName.Count)

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: "pie",
            data: {
                labels: labels,
                datasets: [{
                    borderColor: [
                        airHex,
                        oceanHex,
                        groundHex,
                        urgentAirHex,
                        warehouseHex,
                        brokerageHex
                    ],
                    backgroundColor: [
                        transparentize(airHex),
                        transparentize(oceanHex),
                        transparentize(groundHex),
                        transparentize(urgentAirHex),
                        transparentize(warehouseHex),
                        transparentize(brokerageHex)
                    ],
                    data: chartData,
                    label: 'Material Receipts Warehouse Count By Name'
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            title: () => '',
                            label: (context) => `${context.label}: ${context.formattedValue} MRs`
                        }
                    }
                },
            }
        });

    }, 0);
}

/**
 * @param {String} canvasId
 * @param {PurchaseOrderCountByStatus []} models
 */
export function purhaseOrderCountByStatusChart(canvasId, models) {

    setTimeout(() => {

        const labels = models.map(purchaseOrderCountByStatus => purchaseOrderCountByStatus.StatusName)

        const chartData = models.map(purchaseOrderCountByStatus => purchaseOrderCountByStatus.Count)

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: "bar",
            data: {
                labels: labels,
                datasets: [{
                    borderColor: [
                        airHex,
                        oceanHex,
                        groundHex,
                        urgentAirHex,
                        warehouseHex,
                        brokerageHex
                    ],
                    backgroundColor: [
                        transparentize(airHex),
                        transparentize(oceanHex),
                        transparentize(groundHex),
                        transparentize(urgentAirHex),
                        transparentize(warehouseHex),
                        transparentize(brokerageHex)
                    ],
                    data: chartData
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            title: () => '',
                            label: (context) => `${context.label}: ${context.formattedValue}`
                        }
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },

            }
        });

    }, 0);
}

/**
* @param {String} canvasId
* @param {MaterialReceiptCountByStatus []} models
*/
export function materialReceiptCountByStatusChart(canvasId, models) {

    setTimeout(() => {

        const labels = models.map(materialReceiptCountByStatus => materialReceiptCountByStatus.StatusName)

        const chartData = models.map(materialReceiptCountByStatus => materialReceiptCountByStatus.Count)

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: "bar",
            data: {
                labels: labels,
                datasets: [{
                    borderColor: [
                        airHex,
                        oceanHex,
                        groundHex,
                        urgentAirHex,
                        warehouseHex,
                        brokerageHex
                    ],
                    backgroundColor: [
                        transparentize(airHex),
                        transparentize(oceanHex),
                        transparentize(groundHex),
                        transparentize(urgentAirHex),
                        transparentize(warehouseHex),
                        transparentize(brokerageHex)
                    ],
                    data: chartData,
                    label: 'Material Receipts Count By Status'
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            title: () => '',
                            label: (context) => `${context.label}: ${context.formattedValue}`
                        }
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },

            }
        });

    }, 0);
}

/**
 * @callback BarClicked
 * @param {ShipmentDeliveryPerformance} value
 * @returns {void}
 * 
 * @param {String} canvasId
 * @param {ShipmentDeliveryPerformance []} models
 * @param {BarClicked} handler
*/


export function shipmentOnTimePercentageChart(canvasId, models, handler) {

    setTimeout(() => {

        const labels = models.map(data => `${data.PortOfLadingCityCode} - ${data.PortOfDischargeCityCode}`)

        const lineData = models.map(data => parseFloat(data.OnTimePercentage))

        const barData = models.map(data => data.ShipmentCount)

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        chart = new Chart(document.getElementById(canvasId), {
            type: "bar",
            data: {
                labels: labels,
                datasets: [
                    {
                        type: 'line',
                        label: '% On-Time',
                        backgroundColor: countHex,
                        borderColor: countHex,
                        data: lineData,
                        order: 1,
                        yAxisID: 'right-y-axis'
                    },
                    {
                        type: 'bar',
                        label: 'Shipment Count',
                        backgroundColor: volumeHex,
                        borderColor: volumeHex,
                        data: barData,
                        order: 2,
                        yAxisID: 'left-y-axis'
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    'left-y-axis': {
                        type: 'linear',
                        position: 'left'
                    },
                    'right-y-axis': {
                        type: 'linear',
                        position: 'right',
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        }
                    }
                },
                onClick: (event, elements) => {
                    if (elements.length > 0) {

                        const element = elements[0];
                        const datasetIndex = element.datasetIndex;
                        const dataIndex = element.index;
                        const dataset = chart.data.datasets[datasetIndex];

                        if (dataset.type === 'bar') {
                      
                            handler(models[dataIndex]);
                        }
                    }
                },
            }
        });

    }, 0);
}

/**
 * @callback BarClicked
 * @param {ShipmentDeliveryPerformance} value
 * @returns {void}
 * 
 * @param {String} canvasId
 * @param {ShipmentDeliveryPerformance []} models
 * @param {BarClicked} handler
*/
export function shipmentTransitTimeChart(canvasId, models, handler) {

    setTimeout(() => {

        const labels = models.map(data => `${data.PortOfLadingCityCode} - ${data.PortOfDischargeCityCode}`)

        const lineData = models.map(data => parseFloat(data.MedianTransitTime))

        const barData = models.map(data => data.ShipmentCount)

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        chart = new Chart(document.getElementById(canvasId), {
            type: "bar",
            data: {
                labels: labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Median Transit Time',
                        backgroundColor: transitMedianHex,
                        borderColor: transitMedianHex,
                        data: lineData,
                        order: 1,
                        yAxisID: 'right-y-axis'
                    },
                    {
                        type: 'bar',
                        label: 'Shipment Count',
                        backgroundColor: transitCountHex,
                        borderColor: transitCountHex,
                        data: barData,
                        order: 2,
                        yAxisID: 'left-y-axis'
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    'left-y-axis': {
                        type: 'linear',
                        position: 'left'
                    },
                    'right-y-axis': {
                        type: 'linear',
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,  
                        }
                    }
                },
                onClick: (event, elements) => {
                    if (elements.length > 0) {

                        const element = elements[0];
                        const datasetIndex = element.datasetIndex;
                        const dataIndex = element.index;
                        const dataset = chart.data.datasets[datasetIndex];

                        if (dataset.type === 'bar') {

                            handler(models[dataIndex]);
                        }
                    }
                },
            }
        });

    }, 0);
}

/**
 * 
 * @param {String} canvasId
 * @param {EmissionMetricsByDate []} models
*/
export function shipmentCO2EmissionByMonthChart(canvasId, models) {

    setTimeout(() => {

        const labels = models.map(data => data.FormattedDate)

        const lineData = models.map(data => parseFloat(data.EmissionsIntensity))

        const barData = models.map(data => parseFloat(data.EmissionsTotal))

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        type: 'bar',
                        label: 'WTW CO2e (tonnes)',
                        backgroundColor: '#132D76',
                        data: barData,
                        order: 2,
                        yAxisID: 'left-y-axis'
                    },
                    {
                        type: 'line',
                        label: 'CO2e Intensity (g/tonne km)',
                        backgroundColor: '#F39200',
                        borderColor: '#F39200',
                        data: lineData,
                        order: 1,
                        yAxisID: 'right-y-axis'
                    }
                ],
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    'left-y-axis': {
                        type: 'linear',
                        position: 'left'
                    },
                    'right-y-axis': {
                        type: 'linear',
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        }
                    }
                }
            }
        });
    }, 0);
}

/**
 * 
 * @param {String} canvasId
 * @param {EmissionProductIntensityByDate []} models
*/
export function shipmentEmissionIntensityByProductChart(canvasId, models) {

    setTimeout(() => {

        const colors = ['#7DA4BC', '#132D76', '#F39200', '#2D7D8F'];

        const labels = [...new Set(models.map(data => data.FormattedDate))];

        const groupedData = Object.groupBy(models, item => item.Product)

        const datasets = [];
        const entries = Object.entries(groupedData);

        entries.forEach(([product, data], index) => {
            let counts = data.map(item => parseFloat(item.EmissionsIntensity)); 

            let color = colors[index % colors.length];

            datasets.push({
                label: product,                
                data: counts,               
                backgroundColor: color,    
                borderColor: color,        
                tension: 0.4
            });

        })

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'line',
            data: {
                labels: labels,
                datasets: datasets
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true  
                    }
                }
            }
        });
    }, 0);
}

/**
 * @callback BarClicked
 * @param {PeriodicInvoiceBalance} value
 * @returns {void}
 * 
 * @param {String} canvasId
 * @param {PeriodicInvoiceBalance []} models
 * @param {BarClicked} handler
*/
export function billingCurrentBalanceChart(canvasId, models, handler) {

    setTimeout(() => {

        const labels = models.map(data => data.DateRange)

        const barData = models.map(data => parseFloat(data.AmountUsd))

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        chart = new Chart(document.getElementById(canvasId), {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        axis: 'y',
                        label: 'Amount USD',
                        backgroundColor: '#132D76',
                        data: barData,
                    }
                ],
            },
            options: {
                maintainAspectRatio: false,
                onClick: (event, elements) => {
                    if (elements.length > 0) {

                        const element = elements[0];
                        const datasetIndex = element.datasetIndex;
                        const dataIndex = element.index;
                        const dataset = chart.data.datasets[datasetIndex];


                        handler(models[dataIndex]);
                    }
                }
            }
        });

    }, 0);
}

/**
 * @callback BarClicked
 * @param {PeriodicInvoiceBalance} value
 * @returns {void}
 * 
 * @param {String} canvasId
 * @param {PeriodicInvoiceBalance []} models
 * @param {BarClicked} handler
*/
export function billingPastDueBalanceChart(canvasId, models, handler) {

    setTimeout(() => {

        const labels = models.map(data => data.DateRange)

        const barData = models.map(data => parseFloat(data.AmountUsd))

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        chart = new Chart(document.getElementById(canvasId), {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        axis: 'y',
                        label: 'Amount USD',
                        backgroundColor: '#C44D36',
                        data: barData,
                    }
                ],
            },
            options: {
                maintainAspectRatio: false,
                onClick: (event, elements) => {
                    if (elements.length > 0) {

                        const element = elements[0];
                        const datasetIndex = element.datasetIndex;
                        const dataIndex = element.index;
                        const dataset = chart.data.datasets[datasetIndex];

                        handler(models[dataIndex]);
                    }
                }
            }
        });

    }, 0);
}
