import { Record, Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { record_type, string_type, array_type, union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { DashboardQuery, PeriodicInvoiceBalance_$reflection, InvoiceBalanceReport_$reflection, Account_$reflection, User_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { Currency_formatCurrency, AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { BillingIntentQuery, BillingIntentQuery_$reflection } from "../Models.fs.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Alert_text, Alert_title, Alert_error } from "./SweetAlert.fs.js";
import { Chart_billingPastDueBalanceChart, Chart_billingCurrentBalanceChart } from "./Chart.fs.js";
import { ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { getInvoiceBalanceReports } from "../Api/InvoiceApi.fs.js";
import { tryFind, map } from "../fable_modules/fable-library.4.10.0/Array.js";
import { now, date, compare } from "../fable_modules/fable-library.4.10.0/Date.js";
import { defaultArg, ofNullable } from "../fable_modules/fable-library.4.10.0/Option.js";
import { createElement } from "react";
import { compare as compare_1, printf, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { fromParts } from "../fable_modules/fable-library.4.10.0/Decimal.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { render as render_1 } from "./Loader.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class BillingDashboards extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SpendOverTime", "Balance"];
    }
}

export function BillingDashboards_$reflection() {
    return union_type("CView.UI.BillingDashboard.BillingDashboard.BillingDashboards", [], BillingDashboards, () => [[], []]);
}

export class State extends Record {
    constructor(User, ActiveDashboard, Accounts, InvoicesBalancesReports) {
        super();
        this.User = User;
        this.ActiveDashboard = ActiveDashboard;
        this.Accounts = Accounts;
        this.InvoicesBalancesReports = InvoicesBalancesReports;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.BillingDashboard.BillingDashboard.State", [], State, () => [["User", User_$reflection()], ["ActiveDashboard", BillingDashboards_$reflection()], ["Accounts", array_type(Account_$reflection())], ["InvoicesBalancesReports", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [InvoiceBalanceReport_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", InvoiceBalanceReport_$reflection()]], [["ErrorValue", string_type]]]))]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "BillingSearch"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.BillingDashboard.BillingDashboard.Intent", [], Intent, () => [[], [["Item", BillingIntentQuery_$reflection()]]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectedDashboard", "BarChartClicked", "GetInvoicesBalancesReports", "SearchInvoicesByStatus"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.BillingDashboard.BillingDashboard.Msg", [], Msg, () => [[["Item", BillingDashboards_$reflection()]], [["Item", PeriodicInvoiceBalance_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [InvoiceBalanceReport_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", InvoiceBalanceReport_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", string_type]]]);
}

const BillingCurrentBalanceChartId = "BillingCurrentBalanceChart";

const BillingPastDueBalanceChartId = "BillingPastDueBalanceChart";

export function init(user, accounts) {
    return [new State(user, new BillingDashboards(1, []), accounts, new Deferred$1(0, [])), Cmd_none()];
}

export function update(msg, state) {
    let invoiceBalanceReport;
    switch (msg.tag) {
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.User, state.ActiveDashboard, state.Accounts, new Deferred$1(2, [msg.fields[0].fields[0]])), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Billing Balance Report", Alert_text(msg.fields[0].fields[0].fields[0]))) : ((invoiceBalanceReport = msg.fields[0].fields[0].fields[0], Cmd_batch(ofArray([Chart_billingCurrentBalanceChart(BillingCurrentBalanceChartId, invoiceBalanceReport.CurrentBalances, (Item_2) => (new Msg(1, [Item_2]))), Chart_billingPastDueBalanceChart(BillingPastDueBalanceChartId, invoiceBalanceReport.HistoricalBalances, (Item_3) => (new Msg(1, [Item_3])))])))), new Intent(0, [])];
            }
            else if (equals(state.InvoicesBalancesReports, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.ActiveDashboard, state.Accounts, new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, getInvoiceBalanceReports, new DashboardQuery(map((account) => account.Id, state.Accounts)), (arg) => (new Msg(2, [new AsyncMsg$1(1, [arg])]))), new Intent(0, [])];
            }
        case 1: {
            const isCurrent = compare(date(msg.fields[0].StartDate), date(now())) >= 0;
            const isMaxPastDate = !isCurrent && !(msg.fields[0].EndDate != null);
            const invoiceQuery = new BillingIntentQuery(isCurrent ? "Current" : "Past Due", "Due Date", isMaxPastDate ? void 0 : msg.fields[0].StartDate, isMaxPastDate ? msg.fields[0].StartDate : ofNullable(msg.fields[0].EndDate));
            return [state, Cmd_none(), new Intent(1, [invoiceQuery])];
        }
        case 3: {
            const invoiceQuery_1 = new BillingIntentQuery(msg.fields[0], "", void 0, void 0);
            return [state, Cmd_none(), new Intent(1, [invoiceQuery_1])];
        }
        default:
            return [new State(state.User, msg.fields[0], state.Accounts, state.InvoicesBalancesReports), Cmd_none(), new Intent(0, [])];
    }
}

export function render(state, dispatch) {
    let elems_15, elems_20, elems_18, children, elems_16, elems_17;
    const balanceTab = createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("tab"), delay(() => (!equals(state.ActiveDashboard, new BillingDashboards(1, [])) ? singleton("is-hidden") : empty()))))))], ["id", "balance"], (elems_15 = toList(delay(() => {
        let elems_14, elems_6, elems_1, elems, fmt, input_1, elems_3, elems_2, fmt_1, input_3, elems_5, elems_4, fmt_2, input_5, elems_13, elems_9, elems_8, elems_7, elems_12, elems_11, elems_10;
        const matchValue = state.InvoicesBalancesReports;
        let matchResult, invoicesBalancesReports;
        switch (matchValue.tag) {
            case 2: {
                if (matchValue.fields[0].tag === 0) {
                    matchResult = 0;
                    invoicesBalancesReports = matchValue.fields[0].fields[0];
                }
                else {
                    matchResult = 2;
                }
                break;
            }
            case 1: {
                matchResult = 1;
                break;
            }
            default:
                matchResult = 2;
        }
        switch (matchResult) {
            case 0:
                return singleton(createElement("div", createObj(ofArray([["className", "block"], (elems_14 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_6 = [createElement("div", createObj(ofArray([["className", "column"], (elems_1 = [createElement("div", createObj(ofArray([["className", "box"], (elems = [createElement("h6", {
                    className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-4", "has-text-link"]),
                    children: "Current",
                }), createElement("hr", {}), createElement("div", createObj(ofArray([["className", join(" ", ["is-size-3", "has-text-centered", "has-text-weight-semibold", "has-text-link", "is-clickable"])], ((fmt = printf("%s USD"), fmt.cont((value_15) => ["children", value_15])))(Currency_formatCurrency("en-US", "USD", defaultArg((input_1 = tryFind((x) => (compare_1(x.Status, "Current", 5) === 0), invoicesBalancesReports.BalancesByStatus), (input_1 == null) ? void 0 : input_1.Amount), fromParts(0, 0, 0, false, 0)))), ["onClick", (_arg) => {
                    dispatch(new Msg(3, ["Current"]));
                }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_3 = [createElement("div", createObj(ofArray([["className", "box"], (elems_2 = [createElement("h6", {
                    className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-4", "has-text-danger"]),
                    children: "Past Due",
                }), createElement("hr", {}), createElement("div", createObj(ofArray([["className", join(" ", ["is-size-3", "has-text-centered", "has-text-weight-semibold", "has-text-danger", "is-clickable"])], ((fmt_1 = printf("%s USD"), fmt_1.cont((value_30) => ["children", value_30])))(Currency_formatCurrency("en-US", "USD", defaultArg((input_3 = tryFind((x_2) => (compare_1(x_2.Status, "Past Due", 5) === 0), invoicesBalancesReports.BalancesByStatus), (input_3 == null) ? void 0 : input_3.Amount), fromParts(0, 0, 0, false, 0)))), ["onClick", (_arg_1) => {
                    dispatch(new Msg(3, ["Past Due"]));
                }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_5 = [createElement("div", createObj(ofArray([["className", "box"], (elems_4 = [createElement("h6", {
                    className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-4", "has-text-primary"]),
                    children: "Paid",
                }), createElement("hr", {}), createElement("div", createObj(ofArray([["className", join(" ", ["is-size-3", "has-text-centered", "has-text-weight-semibold", "has-text-primary", "is-clickable"])], ((fmt_2 = printf("%s USD"), fmt_2.cont((value_45) => ["children", value_45])))(Currency_formatCurrency("en-US", "USD", defaultArg((input_5 = tryFind((x_4) => (compare_1(x_4.Status, "Paid", 5) === 0), invoicesBalancesReports.BalancesByStatus), (input_5 == null) ? void 0 : input_5.Amount), fromParts(0, 0, 0, false, 0)))), ["onClick", (_arg_2) => {
                    dispatch(new Msg(3, ["Paid"]));
                }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_13 = [createElement("div", createObj(ofArray([["className", "column"], (elems_9 = [createElement("div", createObj(ofArray([["className", "box"], ["id", "due_soon_billing_chart"], (elems_8 = [createElement("span", {
                    className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-4"]),
                    children: "Current - By Due Date",
                }), createElement("div", createObj(ofArray([["className", "chart-container"], (elems_7 = [createElement("canvas", {
                    id: BillingCurrentBalanceChartId,
                    className: "c-view-chart",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_12 = [createElement("div", createObj(ofArray([["className", "box"], ["id", "past_due_billing_chart"], (elems_11 = [createElement("span", {
                    className: join(" ", ["has-text-weight-semibold", "has-text-danger", "is-size-4"]),
                    children: "Past Due",
                }), createElement("div", createObj(ofArray([["className", "chart-container"], (elems_10 = [createElement("canvas", {
                    id: BillingPastDueBalanceChartId,
                    className: "c-view-chart",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))));
            case 1:
                return singleton(render_1());
            default:
                return singleton(defaultOf());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])));
    return createElement("div", createObj(ofArray([["className", "tab"], ["id", "billing_dashboards"], (elems_20 = [createElement("div", createObj(ofArray([["className", join(" ", ["tabs", "is-small", "is-toggle"])], (elems_18 = [(children = ofArray([createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("has-text-weight-semibold"), delay(() => append(singleton("tablink"), delay(() => (equals(state.ActiveDashboard, new BillingDashboards(0, [])) ? singleton("is-active") : empty()))))))))], (elems_16 = [createElement("a", {
        onClick: (_arg_3) => {
            dispatch(new Msg(0, [new BillingDashboards(0, [])]));
        },
        children: "Spend Over Time",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("has-text-weight-semibold"), delay(() => append(singleton("tablink"), delay(() => (equals(state.ActiveDashboard, new BillingDashboards(1, [])) ? singleton("is-active") : empty()))))))))], (elems_17 = [createElement("a", {
        onClick: (_arg_4) => {
            dispatch(new Msg(0, [new BillingDashboards(1, [])]));
            dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
        },
        children: "Balance",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))]), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]))), createElement("div", {
        className: "tabcontainer",
        id: "billing_tabcontent",
        children: Interop_reactApi.Children.toArray([balanceTab]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])));
}

